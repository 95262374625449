<script setup>
import Error from 'assets/icons/error.svg';
import Success from 'assets/icons/success.svg';
import { useAuthStore } from '~/stores/auth';
import { useSideBar } from '~/stores/sideBar';

const authStore = useAuthStore();
const { updateStatusObj } = authStore;

// Global
const sideBarStore = useSideBar();
const { toggleSideBarSubNav } = sideBarStore;

const props = defineProps({
    statusObj: {
        type: Object,
        default: () => {
        }
    }
});

// Fade message out + go to account after 3 seconds
watchEffect(() => {
    if (props.statusObj?.success) {
        // Hide message
        setTimeout(() => {
            updateStatusObj(null)
        }, 2000);

        if (props.statusObj?.goBack) {
            setTimeout(() => {
                toggleSideBarSubNav({
                    open: false
                });
            }, 4000);
        }
    }
});

const closeForm = () => {
    updateStatusObj(null)
}

onUnmounted(() => {
    updateStatusObj(null)
})

</script>

<template>
    <Transition name="fade">
        <div
            v-if="statusObj?.statusCode || statusObj?.message" class="form-message"
            :class="statusObj?.success ? 'success-message' : 'error-message'"
            @click="closeForm"
        >
            <Success v-if="statusObj?.success" />
            <Error v-else />

            <div class="message-body">
                <!--<h4 v-if="statusObj?.statusCode">{{ statusObj?.statusCode }}</h4>-->
                <p>{{ statusObj?.message }}</p>
            </div>
        </div>
    </Transition>
</template>

<style lang="scss">
.form-message {
    @include form-message;

    .message-body {
        width: calc(100% - 48px);
        text-align: left;
    }
}
</style>
